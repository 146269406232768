import store from "@/store";
let routes = [
  {
    path: "/reset-password-request",
    name: "ResetPasswordRequest",
    component: () => import("@/views/profile/ResetPasswordRequest.vue"),
    meta: {
      slug: "reset-password-request"
    }
  },
  {
    path: "/registration/reset-password-confirm",
    name: "ResetPasswordConfirm",
    props: route => ({
      message: route.params.message,
      queryToken: route.query?.token
    }),
    component: () => import("@/views/profile/ResetPasswordConfirm.vue"),
    meta: { slug: "reset-password" }
  },

  {
    path: "/reset-password-sent",
    name: "ResetPasswordSent",
    props: { showLogin: true },
    component: () => import("@/views/EbsnCategory.vue"),
    meta: { slug: "reset-password-sent" }
  },
  {
    path: "/recover-password-confirm",
    name: "ResetPassword",
    component: () => import("@/views/profile/ResetPassword.vue"),
    meta: { slug: "reset-password" }
  },
  {
    path: "/reset-password-completed",
    name: "ResetPasswordCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    props: { showLogin: true },
    meta: { slug: "reset-password-completed" }
  },
  {
    path: "/registration-user",
    name: "RegistrationUser",
    component: () => import("@/views/profile/RegistrationUser.vue"),
    meta: { slug: "registration-user" }
  },
  {
    path: "/registration-card",
    name: "RegistrationCard",
    component: () => import("@/views/profile/RegistrationForms.vue"),
    props: {
      type: "OldUser",
      isRegisterWithCard: true
    },
    meta: { slug: "registration-card" }
  },
  {
    path: "/registration-nocard",
    name: "RegistrationNoCard",
    component: () => import("@/views/profile/RegistrationForms.vue"),
    props: {
      type: "NewUser",
      isRegisterWithCard: false
    },
    meta: { slug: "registration-nocard" }
  },
  {
    path: "/thankyou-agreement",
    name: "RegistrationCompletedAgreement",
    component: () => import("@/views/profile/RegistrationCompleted.vue"),
    props: {
      mail: null,
      agreement: true
    },
    meta: { slug: "registration-confirm-agreement" }
  },
  {
    path: "/registration/completed",
    name: "RegistrationCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    meta: { slug: ["registration-requested"] }
  },
  {
    path: "/registration/welcome",
    name: "RegistrationCompletedClient",
    component: () => import("@/views/EbsnCategory.vue"),
    meta: { slug: ["registration-welcome"] }
  },
  {
    path: "/registration/confirm",
    name: "RegistrationConfirm",
    component: () => import("@/views/profile/RegistrationConfirmed.vue"),
    meta: { slug: ["registration-confirm"] }
  },
  {
    path: "/registration-social-completed",
    name: "RegistrationSocialCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    meta: { slug: "registration-social-completed" }
  },
  {
    path: "/registration/card-agreement",
    name: "CardAgreement",
    component: () => import("@/views/profile/CardAgreement.vue"),
    meta: { slug: ["registration/card-agreement", "card-agreement"] }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { requiresAuth: true, breadCrumb: "Bacheca" },
    component: () => import("@/views/profile/Dashboard.vue")
  },
  {
    path: "/profile",
    name: "Profile",
    meta: {
      requiresAuth: true,
      breadCrumb: "Area personale",
      slug: ["profile", "profile/dashboard"]
    },
    component: () => import("@/views/profile/Profile.vue"),
    children: [
      {
        path: "gifts",
        name: "ProfileGift",
        meta: {
          requiresAuth: true,
          breadCrumb: "Buoni sconto",
          slug: ["profile", "profile/gifts"]
        },
        component: () => import("@/views/profile/ProfileGift.vue")
      },
      {
        path: "profile-summary",
        name: "ProfileSummary",
        meta: {
          requiresAuth: true,
          breadCrumb: "Il mio profilo",
          showTcoMenu: true,
          slug: ["profile", "profile/profile-summary"]
        },
        component: () => import("@/views/profile/ProfileSummary.vue")
      },
      {
        path: "profile-update",
        name: "ProfileUpdate",
        props: route => ({
          emailchanged: route.query.emailchanged
        }),
        meta: {
          requiresAuth: true,
          breadCrumb: "Il mio profilo",
          slug: ["profile", "profile/profile-update"]
        },
        component: () => import("@/views/profile/ProfileUpdate.vue")
      },
      {
        path: "profile-update-error",
        name: "ProfileUpdateError",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei dati",
          slug: ["profile-error", "profile/profile-update-error"]
        },
        component: () => import("@/views/profile/ProfileUpdateError.vue")
      },
      {
        path: "change-password",
        name: "UpdatePassword",
        meta: {
          requiresAuth: true,
          breadCrumb: "Aggiorna password",
          slug: ["change-password", "profile/change-password"]
        },
        component: () => import("@/views/profile/UpdatePassword.vue")
      },
      {
        path: "profile-confirmed",
        name: "ProfileConfirmed",
        component: () => import("@/views/profile/ProfileConfirmed.vue"),
        meta: {
          requiresAuth: true,
          breadCrumb: "Aggiorna password",
          slug: ["profile-confirmed", "profile/profile-confirmed"]
        }
      },
      {
        path: "profile-updated",
        name: "ProfileUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: {
          requiresAuth: true,
          breadCrumb: "Aggiorna password",
          slug: ["profile-updated", "profile/profile-updated"]
        }
      },
      {
        path: "email-updated",
        name: "EmailUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: { slug: ["profile/email-updated", "email-updated"] }
      },
      {
        path: "invite",
        name: "Invite",
        meta: {
          requiresAuth: true,
          breadCrumb: "Invita i tuoi amici",
          slug: ["invite", "profile/invite"]
        },
        component: () => import("@/views/profile/Invite.vue")
      },
      {
        path: "news-list",
        name: "NewsListPage",
        meta: {
          requiresAuth: true,
          breadCrumb: "Archivio Messaggi",
          slug: "archivio-messaggi"
        },
        component: () => import("@/views/profile/NewsList.vue")
      },
      {
        path: "orders",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei ordini"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Orders",
            path: "",
            meta: {
              requiresAuth: true,
              slug: "profile/orders"
            },
            component: () => import("@/views/profile/Orders.vue"),
            slug: "profile/orders"
          },
          {
            name: "Order",
            path: ":orderId",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio ordine",
              meta: "profile/orders/order"
            },
            component: () => import("@/views/profile/Order.vue")
          }
        ]
      },
      {
        path: "lists",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le tue liste"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Lists",
            path: "",
            meta: {
              requiresAuth: true,
              meta: ["profile/lists", "lists"],
              slug: "profile/lists"
            },
            component: () => import("@/views/profile/Lists.vue")
          },
          {
            path: ":listId",
            name: "List",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio lista"
            },
            component: () => import("@/views/profile/List.vue")
          }
        ]
      },
      {
        path: "preferred",
        name: "Favourites",
        meta: {
          requiresAuth: true,
          breadCrumb:
            store && store.getters["cart/hasFavorites"]
              ? "I miei preferiti"
              : "I più acquistati"
        },
        component: () => import("@/views/profile/Favourites.vue")
      },
      {
        path: "addresses",
        meta: { requiresAuth: true, breadCrumb: "I miei indirizzi" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "Addresses",
            meta: {
              requiresAuth: true,
              slug: "profile/addresses"
            },
            component: () => import("@/views/profile/ProfileAddresses.vue")
          },
          {
            path: "manage/:addressId?",
            name: "EditAddress",

            meta: { requiresAuth: true, breadCrumb: "Dettaglio indirizzo" },
            component: () => import("@/views/profile/EditAddress.vue")
          }
        ]
      },
      {
        path: "payment-method",
        name: "MyPaymentMethods",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le mie carte",
          slug: "profile/payment-method"
        },
        component: () => import("@/views/profile/MyPaymentMethods.vue")
      },
      {
        path: "last-purchase",
        name: "LastPurchase",
        meta: {
          requiresAuth: true,
          breadCrumb: "Ultima spesa",
          slug: "profile/last-purchase"
        },
        component: () => import("@/views/profile/LastPurchase.vue")
      },
      {
        path: "rewards-cart",
        name: "RewardsCart",
        meta: { requiresAuth: true, breadCrumb: "Carrello premi" },
        component: () => import("@/views/profile/RewardsCart.vue")
      },
      {
        path: "requested-rewards",
        name: "RequestedRewards",
        meta: { requiresAuth: true, breadCrumb: "Premi richiesti" },
        component: () => import("@/views/profile/RequestedRewards.vue")
      },
      {
        path: "create-offer",
        name: "CreateOffer",
        meta: { requiresAuth: true, breadCrumb: "Crea la tua offerta" },
        component: () => import("@/views/profile/RequestedRewards.vue")
      },
      {
        path: "donate",
        name: "Donate",
        meta: { requiresAuth: true, breadCrumb: "Dona i tuoi punti" },
        component: () => import("@/views/profile/Donate.vue")
      },
      {
        path: "invoice",
        name: "Invoice",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei documenti",
          slug: "invoice"
        },
        component: () => import("@/views/profile/Invoice.vue")
      },
      {
        path: "paymentduedate",
        name: "PaymentDueDate",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le mie scadenze",
          slug: "duedate"
        },
        component: () => import("@/views/profile/PaymentDueDate.vue")
      }
    ]
  }
];

export default routes;
